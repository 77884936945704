const images = [
    { path: require('./picture/20231111_120835.jpg'), url: require('./visual aid/microbeal__.jpg') },
    { path: require('./picture/20231111_120810.jpg'), url: require('./visual aid/microbeal.jpg') },
    { path: require('./picture/20231111_115309.jpg'), url: require('./visual aid/microzyme_1.jpg') },
    { path: require('./picture/20231111_115915.jpg'), url: require('./visual aid/microzyme_.jpg') },
    { path: require('./picture/20231111_120850.jpg'), url: require('./visual aid/MICROALKA.jpeg') },
    { path: require('./picture/20231111_121134.jpg'), url: require('./visual aid/MICROCOUGH.jpeg') },
    { path: require('./picture/20231111_121157.jpg'), url: require('./visual aid/MICROZYME DROPS.jpeg') },   
    { path: require('./picture/20231111_124051.jpg'), url: require('./visual aid/MICROLIVD.jpeg') },
    { path: require('./picture/20231111_124109.jpg'), url: require('./visual aid/MICROCOUGH-C.jpeg') },
    { path: require('./picture/20231111_121749.jpg'), url: require('./visual aid/MICROLIV.jpeg') },
    { path: require('./picture/20231111_115622.jpg'), url: require('./visual aid/MICROTONE.jpeg') },
    { path: require('./picture/20231111_120944.jpg'), url: require('./visual aid/MICRO BM OIL.jpeg') },
    { path: require('./picture/20231111_115948.jpg'), url: require('./visual aid/ORTHO-NYL OIL.jpeg') },
    { path: require('./picture/20231111_124202.jpg'), url: require('./visual aid/MICROVITA-H.jpeg') },
    { path: require('./picture/20231111_120059.jpg'), url: require('./visual aid/MICROVITA-L.jpeg') },
    { path: require('./picture/20231111_124032.jpg'), url: require('./visual aid/MICROVITA-LD.jpeg') },
    { path: require('./picture/20231111_123954.jpg'), url: require('./visual aid/CYPTAMINE.jpeg') },
    { path: require('./picture/20231111_121308.jpg'), url: require('./visual aid/MECID.jpeg') }, 
    { path: require('./picture/20231111_121215.jpg'), url: require('./visual aid/MICROCOUGH-E.jpeg') },
    { path: require('./picture/20231111_121242.jpg'), url: require('./visual aid/MICROCOUGH-AT.jpeg') }, ];
const visual_aids = [
    require('./visual aid/MICRO BM OIL.jpeg'),
    require('./visual aid/CYPTAMINE.jpeg'),
    require('./visual aid/MECID.jpeg'),
    require('./visual aid/MICROALKA.jpeg'),
    require('./visual aid/microbeal.jpg'),
    require('./visual aid/microbeal__.jpg'),
    require('./visual aid/MICROCOUGH.jpeg'),
    require('./visual aid/MICROCOUGH-C.jpeg'),
    require('./visual aid/MICROCOUGH-E.jpeg'),
    require('./visual aid/MICROCOUGH-AT.jpeg'),
    require('./visual aid/MICROLIV.jpeg'),
    require('./visual aid/MICROLIVD.jpeg'),
    require('./visual aid/MICROTONE.jpeg'),
    require('./visual aid/MICROVITA-H.jpeg'),
    require('./visual aid/MICROVITA-L.jpeg'),
    require('./visual aid/MICROVITA-LD.jpeg'),
    require('./visual aid/microzyme_.jpg'),
    require('./visual aid/microzyme_1.jpg'),
    require('./visual aid/MICROZYME DROPS.jpeg'),
    require('./visual aid/ORTHO-NYL OIL.jpeg'),
  ];

export default {images,visual_aids};
